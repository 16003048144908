import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackgroundCarouselComponent } from './background-carousel/background-carousel.component';

const comps = [
	BackgroundCarouselComponent
]

@NgModule( {
    declarations: [
        ...comps
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ...comps
    ]
} )
export class SharedModule { }
