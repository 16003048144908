import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';

@Injectable( {
	providedIn: 'root'
} )
export class BackgroundsService {

	constructor() { }

	private _backgrounds = [
		'assets/backs/jw_1.jpg',
		'assets/backs/jw_2.jpg',
		'assets/backs/jw_3.jpg',
		'assets/backs/jw_4.jpg',
		'assets/backs/jw_5.jpg',
		'assets/backs/jw_6.jpg',
		'assets/backs/jw_7.jpg',
		'assets/backs/jw_8.jpg',
		'assets/backs/jw_9.jpg',
		'assets/backs/jw_10.jpg',
		'assets/backs/jw_11.jpg',
		'assets/backs/jw_12.jpg',
		'assets/backs/jw_13.jpg',
		'assets/backs/jw_14.jpg',
		'assets/backs/jw_15.jpg'
	];

	getAll(): Observable<string[]> {
		return of( this._backgrounds );
	}
}
