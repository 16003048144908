<jw-background-carousel></jw-background-carousel>
<div class="main-row top"></div>
<div class="main-row logo">
	<img src="/assets/title.svg" alt="Jerzy Wypych - photo, video & graphic design">
</div>
<div class="main-row bottom">
	<div class="col contact">
		<div class="details" [class.hide]="!showDetails">
			<div class="wrap">
				<p>+48<span class="obf" [attr.data]="randomString"></span> 8<span class="obf" [attr.data]="randomString"></span>84 22<span class="obf" [attr.data]="randomString"></span>0 1<span class="obf" [attr.data]="randomString"></span>09</p>
				<p (click)="sendEmail()" class="hover">je<span class="obf" [attr.data]="randomString"></span>rzy<span class="obf" [attr.data]="randomString"></span>wyp<span class="obf" [attr.data]="randomString"></span>ych<span class="obf" [attr.data]="randomString"></span>@gm<span class="obf" [attr.data]="randomString"></span>ail.com</p>
			</div>
		</div>
		<a (click)="showContact()" class="hover">contact</a>
	</div>
	<div class="col social">
		<a href="https://www.facebook.com/wypych.jerzy" class="facebook" target="_blank"><img src="/assets/facebook.svg"></a>
		<a href="https://www.instagram.com/jerzy.wypych" class="instagram" target="_blank"><img src="/assets/instagram.svg"></a>
		<a href="https://vimeo.com/jerzywypych" class="vimeo" target="_blank"><img src="/assets/vimeo.svg"></a>
		<a href="https://www.behance.net/jerzywypych" class="behance" target="_blank"><img src="/assets/behance.svg"></a>
	</div>
	<div class="col"></div>
</div>
