import { Component, OnDestroy, ViewChild, ElementRef, AfterContentInit, ViewChildren, AfterViewInit } from '@angular/core';
import { gsap } from 'gsap';
import { shuffle } from 'lodash';

import { BackgroundsService } from '../../services/backgrounds.service';

@Component( {
	selector: 'jw-background-carousel',
	templateUrl: 'background-carousel.html'
} )
export class BackgroundCarouselComponent implements AfterViewInit, OnDestroy {

	@ViewChild( 'carousel', { static: false } ) carousel: ElementRef;

	constructor(
		private backgroundService: BackgroundsService
	) { }

	backgrounds: string[] = [];
	timeline: gsap.core.Timeline;

	private _backgrounds$ = this.backgroundService
		.getAll()
		.subscribe( backs => this.backgrounds = shuffle( backs ) );

	ngAfterViewInit() {
		const backs = this.carousel.nativeElement.children as HTMLCollection;
		const animationLength = 3;
		const delay = 3;

		this.timeline = gsap.timeline( { repeat: -1, delay: animationLength } );

		gsap.to( backs.item( 0 ), { duration: animationLength, autoAlpha: 1 } );

		for ( let i = 1; i < backs.length; i++ ) {
			this.timeline.add( gsap.to( backs.item( i ), { duration: animationLength, autoAlpha: 1, delay } ) );
		}

		for ( let i = 1; i < backs.length; i++ ) {
			if ( i !== backs.length - 1 ) {
				this.timeline.add( gsap.to( backs.item( i ), { duration: 0, alpha: 0 } ) );
			} else {
				this.timeline.add( gsap.to( backs.item( i ), { duration: animationLength, alpha: 0, delay } ) );
			}
		}
	}

	ngOnDestroy() {
		if ( this._backgrounds$ ) {
			this._backgrounds$.unsubscribe();
		}
	}

	getBack( backSrc: string ): string {
		return `url(${backSrc})`;
	}

}
